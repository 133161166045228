<template>
  <div>
    <div>
      <span>科类:</span>
      <!-- <span class="info_2">{{ userData.is_wenli == 1 ? '文科' : '理科' }}</span> -->

      <span class="info_2 span1">{{ userData.subject == 1 ? '物' : '史' }}</span>
      <span v-if="userData.other_subject" class="info_2">
        <span class="span1 info_2" v-for=" (item, key) in JSON.parse(userData.other_subject) " :key="key">
          <span class="span1 info_2" v-if="item == 2">化</span>
          <span class="span1 info_2" v-if="item == 3">生</span>
          <span class="span1 info_2" v-if="item == 6">政</span>
          <span class="span1 info_2" v-if="item == 5">地</span>
        </span>
      </span>


      <span>分数:</span>
      <span class="info_2">{{ userData.score }}分</span>
      <span>位次:&nbsp;</span>
      <span class="info_2">{{ equalScore[0] }}</span>
      <span>线差:&nbsp;</span>
      <span class="info_2">{{ userData.score - fencha }}</span>
      <span>我的同位分：</span>
      <span class="info_3">{{ equalScore[1] }}分</span>
      <span class="info_4">/23年</span>
      <span class="info_3">{{ equalScore[2] }}分</span>
      <span class="info_4">/22年</span>
      <span class="info_3">{{ equalScore[3] }}分</span>
      <span class="info_4">/21年</span>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    fencha: Number
  },
  computed: { ...mapState(["userData", 'equalScore']) },
};
</script>

<style scoped lang='less'>
.info_2 {
  color: #1787e0;
  margin-right: 21px;
}

.span1 {
  margin-right: 0px;
}

.info_3 {
  color: #ff6600;
  margin-left: 15px;
}

.info_4 {
  color: #a5a3a2;
}
</style>
