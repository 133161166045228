<template>
  <div class="main">
    <div>
      <el-row class="user_info info_data">
        <span>学员姓名:{{ userData.user_name }}</span>
        <span>性别:{{ userData.is_sex == 1 ? " 男" : " 女" }}</span>
        <span>民族:汉</span>
        <span>分数:{{ userData.score }}</span>
        <span class="span1">科类: {{ userData.subject == 1 ? '物' : '史' }}</span>
        <span v-if="userData.other_subject">
          <!-- <span class="span1" v-for=" (item, key) in JSON.parse(userData.other_subject) " :key="key">
            <span class="span1" v-if="item == 2">化</span>
            <span class="span1" v-if="item == 3">生</span>
            <span class="span1" v-if="item == 6">政</span>
            <span class="span1" v-if="item == 5">地</span>
          </span> -->
        </span>
        <span>高中:{{ userData.profile.school }}</span>
        <span>英语:{{ userData.profile.english }}</span>
        <span>数学:{{ userData.profile.math }}</span>
        <span>语文:{{ userData.profile.chinese }}</span>
        <span>联系方式:{{ userData.mobile }}</span>
        <span>卡号:{{ userData.account }}</span>
      </el-row>
      <el-row class="user_info" v-if="userData.profile.like_sp">
        <span>意向专业:</span>
        <span class="item">{{ userData.profile.like_sp }}</span>
      </el-row>
      <el-row class="user_info" v-if="userData.profile.no_like_sp">
        <span>排除专业:</span>
        <span class="item">{{ userData.profile.no_like_sp }}</span>
      </el-row>
      <el-row class="user_info" v-if="userData.profile.like_city && userData.profile.like_city != '[]'">
        <span>喜欢城市:</span>
        <span class="item" v-for="(item, k) in JSON.parse(userData.profile.like_city)" :key="k">
          {{ item }}
        </span>
      </el-row>
      <el-row class="user_info" v-if="userData.profile.no_like_city && userData.profile.no_like_city != '[]'">
        <span>排除城市:</span>
        <span class="item" v-for="(item, k) in JSON.parse(userData.profile.no_like_city)" :key="k">
          {{ item }}
        </span>
      </el-row>
      <el-row class="user_info" v-if="userData.profile.special_request && userData.profile.special_request != '[]'">
        <span>特殊升学要求:</span>
        <span class="item" v-for="(item, k) in JSON.parse(userData.profile.special_request)" :key="k">
          {{ item }}
        </span>
      </el-row>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {
    };
  },
  computed: { ...mapState(["userData"]) },
};
</script>

<!--user_info-->
<style lang="less" scoped>
.main {
  margin-top: 7px;
  background: white;

  .user_info {
    line-height: 30px;
    padding: 5px;
    font-size: 14px;

    .span1 {
      margin-right: 0px;
    }

    span {
      display: inline-block;
    }

    .item {
      background: #f2f2f2;
      padding: 0 7px;
      margin-right: 7px;
    }
  }

  .info_data {
    span {
      margin-right: 20px;
    }
  }

}
</style>