<template>
  <div>

    <el-row type="flex" align="middle" :gutter="100">
      <el-col :span="2">
        <el-avatar class="school_logo" :size="80" :src="collegeData.logo" />
      </el-col>
      <el-col :span="12">
        <p class="row">
          <span>{{ collegeData.name }}</span>
          <span v-for="(tip, i) in collegeData.school_tip_id" :key="i" class="tag">{{
            tip
          }}</span>
          <span style="color:#666666;margin-left: 20px;">[{{ collegeData.demand }}]</span>
          <span v-if="collegeData.title == '中外合作办学'" style="color:#666666;margin-left: 20px;">[{{ collegeData.title
            }}]</span>
        </p>
        <p style="color:#666666;margin-top: 20px;">
          <span>办学类型：{{ collegeData.nature }}</span>
          <span v-if="collegeData.school_belong">隶属：{{ collegeData.school_belong }}</span>
          <span v-if="collegeData.city_name">地址：{{ collegeData.city_name }}</span>
        </p>
      </el-col>
    </el-row>

    <div class="school_group">
      <el-row type="flex" align="middle" :gutter="10">
        <el-col :span="2">
          <div class="title">
            院校<br> 专业组
          </div>
        </el-col>
        <el-col :span="18" v-if="this.select_code">
          <div class="group" v-for="(item, i) in CollegeListData" :key="i" @click="chooseGroup(item)">
            <div :class="{ act: item.select_code === select_code, group_item: true }">
              <p>[
                {{ item.school_id }}
                - {{ item.num }}
                ]
              </p>
              <p>更新中</p>
            </div>
            <div class="tianbao">
              填报为
            </div>

          </div>

        </el-col>
        <el-col :span="4">
          服从调剂：
          <el-radio-group v-model="collegeData.tiaoji" @change="getTiaoji()">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
    </div>



    <el-divider class="divider_CP"></el-divider>
    <el-row style="margin-top: 25px" :gutter="20" class="plan_tab1">
      <el-col :span="12">

        <el-table :data="planData" class="tab_plan" height="325"
          :header-cell-style="{ background: 'white', color: '#555', padding: '3px 0' }"
          :cell-style="{ background: 'white', padding: '3px 0' }" style="width: 100%" border v-loading='loading'>
          <el-table-column label="今年招生计划">
            <el-table-column type="index" label="代码" align="center" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column prop="specialty_id" label="代码" width="49px" align="center">
              <template slot-scope="scope">
                {{ scope.row.specialty_id | interpo }}
              </template>
</el-table-column> -->
            <el-table-column prop="specialty_name" label="专业名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="direction" label="专业备注" show-overflow-tooltip align="center" width="60">
            </el-table-column>
            <el-table-column prop="plan_num" label="招生计划" align="center" width="60">
            </el-table-column>
            <el-table-column prop="tuition" label="学费/年" align="center" width="100"> </el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <div v-if="change">
                  <el-button v-if="spList.indexOf(scope.row.specialty_name) > -1" @click="chooseSpecialty(scope.row)"
                    size="mini" type="primary">
                    已选择 {{ spList.indexOf(scope.row.specialty_name) + 1 }}
                  </el-button>
                  <el-button v-else @click="chooseSpecialty(scope.row)" size="mini">
                    填报
                  </el-button>
                </div>
                <div v-else>
                  <el-button @click="chooseSpecialty(scope.row)" size="mini">填报</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-col>

      <el-col :span="12">
        <MojarOld :school_id='collegeData.school_id' :select_code="collegeData.select_code" :searchKey='searchKey'
          :collegeData="collegeData" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MojarOld from "./MojarOld";
import { mapState } from "vuex";
export default {
  props: ["collegeData"],
  name: "",
  components: {
    MojarOld
  },
  data() {
    return {
      planData: [],
      loading: true,
      searchKey: "",
      tiaoji: true,
      change: false,
      spList: [],
      select_code: 1,
      CollegeListData: []
    };
  },
  computed: { ...mapState(["record", 'userData']) },
  mounted() {

    this.new_batch = JSON.parse(localStorage.getItem("new_batch"));
    this.select_code = this.collegeData.select_code
    let index = this.record.id.indexOf(this.select_code)

    //获取学校所有组
    this.getList()

    if (index > -1) {
      this.collegeData.tiaoji = this.record.college[index].tiaoji
      if (this.record.sp[index]) {
        this.spList = this.record.sp[index]
      } else {
        this.spList = []
      }

      this.change = true
      this.$forceUpdate
    }

    //获取批次

    this.getPlan();
  },

  methods: {
    //搜索院校
    getList() {
      if (this.new_batch == 0) {
        return;
      }
      this.$fecth
        .post("volunteer/GetCollegeGroup", {
          name: this.collegeData.name,
          subject_id: this.userData.subject_id,
          new_batch: this.new_batch,
        })
        .then((res) => {
          console.log(res, 'collegeList')
          res = res.data;
          this.CollegeListData = res.lists;
        });
    },

    chooseGroup(item) {
      this.select_code = item.select_code
      this.getPlan()
    },

    //获取数据
    getPlan() {
      if (this.select_code) {
        this.loading = true;
        this.$fecth
          .post("volunteer/getPlan", {
            select_code: this.select_code,
            new_batch: this.new_batch,
          })
          .then((res) => {
            res = res.data;
            this.planData = res;
            this.loading = false;
          });
      }
    },

    //选择专业
    chooseSpecialty(item) {
      // //判断是否选择志愿位置
      let index = this.record.id.indexOf(this.select_code);
      if (index > -1) {
        let sp_id = this.spList.indexOf(item.specialty_name);
        if (sp_id > -1) {
          this.record.sp[index].splice(sp_id, 1);
          this.record.specialty[index].splice(sp_id, 1)
        } else {
          //判断是否已经选了5个志愿
          let splen = this.spList.length;
          if (splen == 5) {
            this.$alert("每个志愿最多填报5个专业！", "提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
          this.searchKey = item.specialty_name
          this.record.sp[index].push(item.specialty_name);
          this.record.specialty[index].push(item);
          this.$store.commit('setRecord', this.record)
        }
        this.updateReport()
      } else {
        this.$alert("请先选择院校", "提示", {
          confirmButtonText: "确定",
        }).catch((msg) => {
          console.log(msg);
        });
      }
    },

    updateReport() {
      console.log(this.$store.state.record, 12312)
      this.$fecth
        .post("volunteer/updateReport", {
          user_id: this.$route.query.id,
          subject: this.userData.subject,
          new_batch: this.new_batch,
          record: JSON.stringify(this.record)
        })
    },

    getTiaoji() {
      this.$store.commit('setRecord', this.record);
      this.updateReport()
    },
  },
};
</script>

<style scoped lang='less'>
.row {
  font-size: 25px;
  color: black;
}

.school_group {
  margin-top: 20px;

  .title {
    font-size: 22px;
    text-align: center;
  }

  .group {
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    margin-right: 25px;
    display: inline-block;
  }

  .group_item {
    border: 1px solid red;
    display: inline-block;
    padding: 10px;
    color: red;
    width: 100px;
    vertical-align: bottom;
    border-radius: 5px 0 0 5px;
  }

  .tianbao {
    width: 25px;
    padding: 5px 0;
    display: inline-block;
    line-height: 107%;
    vertical-align: bottom;
    border: 1px solid red;
    color: red;
    display: inline-block;
    border-left: 0;
    border-radius: 0 5px 5px 0;
  }

  // .act {
  //   border: 1px solid blue;
  //   color: blue;
  //   display: inline-block;
  //   width: 80px;
  //   text-align: center;
  //   border-radius: 4px;
  //   margin-right: 25px;
  //   padding: 5px;
  // }
}



.info {
  font-size: 14px;
  position: relative;

  span {
    display: inline-block;
  }
}
</style>

<style lang="less">
.tab_plan {

  .el-table td,
  .el-table th {
    padding: 0px;
  }
}

.divider_CP {
  margin: 10px 0;
}
</style>