<template>
  <div>
    <conditional-search @onSearch='onSearch' @onSearchAll="onSearchAll" />
    <div style="margin-bottom:30px;height: 30px;">
      <el-col :span="10">位次区间：
        <el-input placeholder="最低位次" style="width:143px;" v-model="check.min_ranking" size="mini">
        </el-input> - <el-input placeholder="最高位次" style="width:143px" v-model="check.max_ranking" size="mini">
        </el-input>
      </el-col>
      <el-col :span="9" :offset="1"> <span style="margin-left: 16px;margin-right: 7px;">显示数量：</span>
        <el-select v-model="page.pageSize" @change="getList()" placeholder="请选择" size="mini">
          <el-option v-for="item in options" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <span style="float:right;background:#2b7bf3;color:white;padding: 3px 12px;" size="mini"
          @click="getList()">搜索</span>
      </el-col>
    </div>

    <div style="min-height: 100px">
      <college-list v-for="(item, i) in CollegeData" :item="item" :len="len" :fencha="fencha" :key="i"
        @refush=updateReport>
      </college-list>
    </div>

    <div class="main">
      <template>
        <div style="text-align: center; margin-bottom: 20px">
          <el-pagination @current-change="handleCurrentChange" :page-size="this.page.pageSize" layout=" prev, pager, next"
            :total="this.page.total">
          </el-pagination>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ConditionalSearch from "../ConditionalSearch.vue";
import { mapState } from "vuex";
import CollegeList from "./CollegeListFree.vue";
export default {
  name: 'free-modeule',
  props: ['len', 'batch', 'fencha'],
  components: {
    ConditionalSearch, CollegeList
  },
  data() {
    return {
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", '十', '十一', '十二'],
      CollegeData: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      options: [10, 20, 30],
      check: {
        degree: null,
        city_id: [],
        nature_id: null,
        school_type_id: [],
        schoolTipId: [],
        max_ranking: '',
        min_ranking: ''
      },
    };
  },
  computed: { ...mapState(["userData", 'record']) },
  watch: {
    batch() {
      this.getList()
    }
  },
  methods: {

    //判断是否条件筛选
    onSearch(item) {
      this.check = item;
      if (this.check.input1 || this.check.input2) {
        this.getLists();
      } else {
        this.getList();
      }
    },

    onSearchAll(item) {
      this.$fecth
        .post("volunteer/getSearchAll", {
          score: this.userData.score,
          is_wenli: this.userData.is_wenli,
          batch: this.batch,
          keyword: item.oldSearch,
        })
        .then((res) => {
          this.CollegeData = res.data.lists;
          this.page.total = res.data.count;
        });
    },

    //按照位次搜索
    getList() {
      if (this.batch == 0) {
        return;
      }
      let url = "volunteer/GetMinSchoolList";
      this.$fecth
        .post(url, {
          score: this.userData.score,
          is_wenli: this.userData.is_wenli,
          batch: this.batch,
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
          max_ranking: this.check.max_ranking,
          min_ranking: this.check.min_ranking,
        })
        .then((res) => {
          res = res.data;
          this.CollegeData = res.lists;
          this.school_child = res.school_child;
          this.page.total = res.count;
        });
    },

    //按条件搜索
    getLists() {
      if (this.batch == 0) {
        return;
      }
      let str1 = "volunteer/collegeSearch";
      let str2 = "volunteer/getMajorFirst";
      let url = "";
      let keyword = '';
      if (this.check.input1) {
        keyword = this.check.input1
        url = str1;
      } else {
        url = str2;
        keyword = this.check.input2
      }
      this.$fecth
        .post(url, {
          score: this.userData.score,
          is_wenli: this.userData.is_wenli,
          keyword: keyword,
          batch: this.batch,
        })
        .then((res) => {
          res = res.data;
          this.loading = false;
          this.CollegeData = res.lists;
          this.school_child = res.school_child;
          this.page.total = res.count;
        });
    },

    //修改就更新志愿表
    updateReport() {
      this.$fecth
        .post("volunteer/updateReport", {
          user_id: this.$route.query.id,
          is_wenli: this.userData.is_wenli,
          ranking: this.userData.ranking,
          batch: this.batch,
          record: JSON.stringify(this.record)
        })
    },

    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },

  },
};
</script>

<style scoped lang='less'></style>