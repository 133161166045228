<template>
  <div style="width: 100%;">
    <el-row type="flex" align="middle" :gutter="100">
      <el-col :span="2" :offset="1">
        <el-avatar class="school_logo" :size="80" :src="item.logo" />
      </el-col>
      <el-col :span="14">
        <p class="row">
          <span>{{ item.name }}</span>
          <span v-if="item.school_tip">[
            <span v-for="(tip, i) in item.school_tip" :key="i" class="tag">{{
              tip
              }}</span>]
          </span>

          <span style="color:#666666;margin-left: 20px;">[{{ item.demand }}]</span>
          <span v-if="item.title == '中外合作办学'" style="color:#666666;margin-left: 20px;">[{{ item.title }}]</span>
        </p>
        <p style="color:#666666;margin-top: 20px;">
          <span style="margin-right: 20px;">办学类型：{{ item.nature }}</span>
          <!-- <span style="margin-right: 20px;"  v-if="item.school_belong">隶属：{{ item.school_belong }}</span> -->
          <span v-if="item.city_name">地址：{{ item.address }}</span>
        </p>

      </el-col>
      <el-col :span="2">
        <el-popover v-model="item.visible" popper-class="popover-f" placement="bottom" trigger="manual">
          <div class="popover-box">
            <p class="box-title">填报为</p>
            <span v-for="h in len" :key="h" class="box-item" @click="chooseCollege(item, h)">
              {{ h }}
            </span>
          </div>

          <el-button v-if="record.id.indexOf(item.select_code) > -1" slot="reference" type="primary"
            @click="changeAlert(item)">
            志愿{{ record.dg[record.id.indexOf(item.select_code)] }}
          </el-button>
          <el-button v-else slot="reference" @click="changeAlert(item)">填报为</el-button>
        </el-popover>
      </el-col>
      <el-col :span="2"> <el-button @click="getSpecialty(item)">可选专业{{ item.num }}</el-button></el-col>
    </el-row>

    <table class="tb1" border="1px">
      <tbody>
        <tr>
          <td rowspan="4" class="school_data">
            冲
          </td>
          <td class="bg_line">年份</td>
          <td class="bg_line">计划数</td>
          <td class="bg_line">录取数</td>
          <td class="bg_line">最高分</td>
          <td class="bg_line">平均分</td>
          <td class="bg_line">最低分</td>
          <td class="bg_line">最低分线差</td>
          <td class="bg_line">最低位次</td>
          <td class="bg_line">比我的位次</td>
        </tr>
        <tr>
          <!--                    年份-->
          <td>{{ item.year }}</td>
          <!--计划数 -->
          <td>{{ item.plan_num_1 }}</td>
          <!--  录取数-->
          <td>{{ item.matriculate_1 }}</td>
          <!--  最高分-->
          <td>{{ item.max_score_1 }}</td>
          <!--  平均分-->
          <td>{{ item.mean_score_1 }}</td>
          <!--  最低分-->
          <td>{{ item.min_score_1 }}</td>
          <td v-if="item.min_score_1">{{ item.min_score_1 - oldLineScore[1].score }}</td>
          <td v-else>-</td>
          <!--  最低分位次-->
          <td style="color: red">{{ item.ranking_1 }}</td>
          <td v-if="item.ranking_1">{{ item.ranking_1 - equalScore[0] }}</td>
          <td v-else>-</td>
        </tr>
        <tr>
          <!--                    年份-->
          <td>{{ item.year - 1 }}</td>
          <!--计划数 -->
          <td>{{ item.plan_num_2 }}</td>
          <!--  录取数-->
          <td>{{ item.matriculate_2 }}</td>
          <!--  最高分-->
          <td>{{ item.max_score_2 }}</td>
          <!--  平均分-->
          <td>{{ item.mean_score_2 }}</td>
          <!--  最低分-->
          <td>{{ item.min_score_2 }}</td>
          <td v-if="item.min_score_2">{{ item.min_score_2 - oldLineScore[2].score }}</td>
          <td v-else>-</td>
          <!--  最低分位次-->
          <td style="color: red">{{ item.ranking_2 }}</td>
          <td v-if="item.ranking_2">{{ item.ranking_2 - equalScore[0] }}</td>
          <td v-else>-</td>
        </tr>
        <tr>
          <!--                    年份-->
          <td>{{ item.year - 2 }}</td>
          <!--计划数 -->
          <td>{{ item.plan_num_3 }}</td>
          <!--  录取数-->
          <td>{{ item.matriculate_3 }}</td>
          <!--  最高分-->
          <td>{{ item.max_score_3 }}</td>
          <!--  平均分-->
          <td>{{ item.mean_score_3 }}</td>
          <!--  最低分-->
          <td>{{ item.min_score_3 }}</td>
          <td v-if="item.min_score_3">{{ item.min_score_3 - oldLineScore[2].score }}</td>
          <td v-else>-</td>
          <!--  最低分位次-->
          <td style="color: red">{{ item.ranking_3 }}</td>
          <td v-if="item.ranking_3">{{ item.ranking_3 - equalScore[0] }}</td>
          <td v-else>-</td>
        </tr>
      </tbody>
    </table>
    <el-dialog :visible.sync="dialogVisible" class="mojarDialog" width="1200px" destroy-on-close>
      <mojar-page :collegeData="collegeData" v-if="dialogVisible" />
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import mojarPage from "../mojar_page/index.vue";
export default {
  name: '',
  props: {
    item: Object,
  },
  computed: { ...mapState(["record", 'userData', 'oldLineScore', 'equalScore']) },
  data() {
    return {
      collegeData: [],
      dialogVisible: false,
      len: 45

    };
  },
  components: {
    mojarPage,
  },
  filters: {
    Zero(e) {
      if (e > -100) {
        return e;
      } else {
        return "-";
      }
    },
  },

  methods: {
    ...mapMutations(['setRecord']),
    //填报为
    changeAlert(item) {
      item.tiaoji = 1;
      item.visible = !item.visible;
      this.$forceUpdate();
    },

    chooseCollege(item, i) {
      let index = this.record.id.indexOf(item.select_code)
      if (index > -1) {
        this.record.id.splice(index, 1)
        this.record.dg.splice(index, 1)
        this.record.college.splice(index, 1)
        this.record.sp.splice(index, 1)
        this.record.specialty.splice(index, 1)
      }
      this.record.id.push(item.select_code)
      this.record.dg.push(i)
      this.record.college.push(item)
      this.record.sp.push([])
      this.record.specialty.push([])
      item.visible = false
      this.$store.commit('setRecord', this.record)
      this.$emit('refush')
      this.$forceUpdate();
    },
    getSpecialty(item) {
      this.collegeData = item;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped lang='less'>
.tb1 {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  border-bottom: 0px;

  tbody {
    line-height: 28px;

    .bg_line {
      background: #f4f7fc;
    }
  }
}

//选择志愿框</style>


<style lang="less">
.popover-f {
  width: 240px;
  color: black;
  min-width: 0;

  .box-title {
    padding-bottom: 10px;
    border-bottom: 1px solid white;
  }

  .box-item {
    display: inline-block;
    padding: 5px 0;
    font-size: 14px;
    border: 1px solid gray;
    margin: 5px 5px;
    width: 30px;
    text-align: center;
  }
}
</style>